import { Suspense, lazy, useEffect } from "react";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { AuthChildren } from "src/components/common/AuthChildren/AuthChildren";
import MainLayout from "src/components/layouts/main/MainLayout";
import AuthLayout from "src/components/layouts/auth/AuthLayout";
import SuspenseLoader from "src/components/suspenseLoader";
import MasterLoader from "src/components/masterLoader";
// import NotFound from "src/pages/main/NotFound";
import { useModuleQuery } from "src/store/services/setting/settingModuleApi";
import { useConfigQuery } from "src/store/services/configApi";
import { Config } from "src/models/api/config.model";
import { useSelector } from "react-redux";
import { selectToken } from "src/store/services/authApi";
import { roleCondition } from "src/utils/role-condition";
import { customModule } from "src/utils/role-module";
import { Module } from "src/models/api/settingModule.model";
import { useDispatch } from "react-redux";
import { switchLoader } from "src/store/slice/loaderSlice";
// ----------------------------- loader ----------------------------------//
const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const LazyLoader = (promise: Promise<any>, time?: number) =>
  Loader(
    lazy(async () => {
      await new Promise((resolve) => setTimeout(resolve, time ?? 0));
      return promise;
    })
  );

// ----------------------------- loan-request ----------------------------------//
const LoanRequestList = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestList"));

// ----------------------------- loan-request-adddata ----------------------------------//
const LayoutLoanRequestAddData = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LayoutLoanRequestAddData"));
const LoanRequestAddDataRoomPlan = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataRoomPlan"));
const LoanRequestAddDataUser = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataUser"));
const LoanRequestAddDataBank = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataBank"));
const LoanRequestAddDataRecord = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataRecord"));
const LoanRequestAddDataApprove = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataApprove"));

// ----------------------------- loan-request-adduser ----------------------------------//
const LayoutLoanRequestAddUser = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LayoutLoanRequestAddUser"));
const LoanRequestAddUserInformation = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserInformation"));
const LoanRequestAddUserAddress = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserAddress"));
const LoanRequestAddUserDocuments = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserDocuments"));
const LoanRequestAddUserRequestLoan = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserRequestLoan"));

// ----------------------------- loan-status----------------------------------//
const LoanCaseList = LazyLoader(import("src/pages/main/LoanStatus/LoanCaseList"));
const LoanStatusList = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusList"));

const LayoutLoanStatusData = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LayoutLoanStatusData"));
const LoanStatusDataRoomplan = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataRoomplan"));
const LoanStatusDataLoan = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataLoan"));
const LoanStatusDataBorrower = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataBorrower"));
const LoanStatusDataCollateral = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataCollateral"));
const LoanStatusDataStatus = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataStatus"));

// ----------------------------- report ----------------------------------//
const Report = LazyLoader(import("src/pages/main/Report"));

// ----------------------------- profile ----------------------------------//
const Profile = LazyLoader(import("src/pages/main/Profile"));

// ----------------------------- profile ----------------------------------//
const Notifications = LazyLoader(import("src/pages/main/Notifications"));

// ----------------------------- setting ----------------------------------//
const LayoutSettingManagement = LazyLoader(import("src/pages/main/Setting/SettingManagement/LayoutSettingManagement"));
const SettingUserManagement = LazyLoader(import("src/pages/main/Setting/SettingManagement/SettingUserManagement"));
const SettingRoleManagement = LazyLoader(import("src/pages/main/Setting/SettingManagement/SettingRoleManagement"));
const SettingUserData = LazyLoader(import("src/pages/main/Setting/SettingUserManagement/SettingUserData"));
const SettingRoleData = LazyLoader(import("src/pages/main/Setting/SettingRoleManagement/SettingRoleData"));
const SettingRoleManageUser = LazyLoader(import("src/pages/main/Setting/SettingRoleManagement/SettingRoleManageUser"));
const SettingRoleManageRoleMenu = LazyLoader(import("src/pages/main/Setting/SettingRoleManagement/SettingRoleManagementRole/RoleMenu"));
const SettingRoleManageRolePermission = LazyLoader(
  import("src/pages/main/Setting/SettingRoleManagement/SettingRoleManagementRole/RolePermission")
);

const LayoutSettingCompany = LazyLoader(import("src/pages/main/SettingCompany/LayoutSettingCompany"));
const SettingCompany = LazyLoader(import("src/pages/main/SettingCompany/Company/SettingCompany"));
const SettingCompanyAdd = LazyLoader(import("src/pages/main/SettingCompany/Company/SettingCompanyAdd"));
const SettingProject = LazyLoader(import("src/pages/main/SettingCompany/Project/SettingProject"));
const SettingProjectAdd = LazyLoader(import("src/pages/main/SettingCompany/Project/SettingProjectAdd"));
const SettingProjectType = LazyLoader(import("src/pages/main/SettingCompany/ProjectType/SettingProjectType"));
const SettingProjectTypeAdd = LazyLoader(import("src/pages/main/SettingCompany/ProjectType/SettingProjectTypeAdd"));
const SettingBusiness = LazyLoader(import("src/pages/main/SettingCompany/Business/SettingBusiness"));
const SettingBusinessAdd = LazyLoader(import("src/pages/main/SettingCompany/Business/SettingBusinessAdd"));
const SettingOccupation = LazyLoader(import("src/pages/main/SettingCompany/Occupation/SettingOccupation"));
const SettingOccupationAdd = LazyLoader(import("src/pages/main/SettingCompany/Occupation/SettingOccupationAdd"));
const SettingOccupationGroup = LazyLoader(import("src/pages/main/SettingCompany/OccupationGroup/SettingOccupationGroup"));
const SettingOccupationGroupAdd = LazyLoader(import("src/pages/main/SettingCompany/OccupationGroup/SettingOccupationGroupAdd"));
const SettingDocument = LazyLoader(import("src/pages/main/SettingCompany/Document/SettingDocument"));
const SettingDocumentAdd = LazyLoader(import("src/pages/main/SettingCompany/Document/SettingDocumentAdd"));
const SettingBank = LazyLoader(import("src/pages/main/SettingCompany/Bank/SettingBank"));
const SettingBankAdd = LazyLoader(import("src/pages/main/SettingCompany/Bank/SettingBankAdd"));

// ----------------------------- auth ----------------------------------//
const LoginForm = LazyLoader(import("src/pages/auth/LoginForm"));
const VerifyPasswordForm = LazyLoader(import("src/pages/auth/SetPassword/VerifyPasswordForm"));
const ResetPasswordForm = LazyLoader(import("src/pages/auth/SetPassword/ResetPasswordForm"));
const ForgotPasswordForm = LazyLoader(import("src/pages/auth/ForgotPassword/ForgotPasswordForm"));

// ----------------------------- report ----------------------------------//
const NotFound = LazyLoader(import("src/pages/main/NotFound/NotFound"));
const NotAuthorized = LazyLoader(import("src/pages/main/NotAuthorized/NotAuthorized"));
// ----------------------------- main-routes ----------------------------------//
const protectedLayout = (
  <AuthChildren>
    <MasterLoader>
      <MainLayout />
    </MasterLoader>
  </AuthChildren>
);
const protectedLayoutFunction = (module: Module[], config: Config[]) => (
  <AuthChildren>
    <MasterLoader>
      <MainLayout module={module} config={config} />
    </MasterLoader>
  </AuthChildren>
);

const protectedLayoutHideSidebar = (
  <AuthChildren>
    <MainLayout hideSidebar={true} />
  </AuthChildren>
);

const pathAfterLogin = (module: Module[]) => {
  const menuLoanRequest = module["1"];
  const menuLoanStatus = module["2"];
  const menuReport = module["3"];
  const menuSetting = module["4"];
  const menuSettingCompany = module["5"];

  if (menuLoanRequest?.is_edit === 1) return <Navigate to="/loan/request/list" />;
  if (menuLoanStatus?.is_edit === 1) return <Navigate to="loan/case/list" />;
  if (menuReport?.is_edit === 1) return <Navigate to="report" />;
  if (menuSetting?.is_edit === 1) return <Navigate to="setting" />;
  if (menuSettingCompany?.is_edit === 1) return <Navigate to="company-setting" />;
};

const MainRoutesFunction = (module: Module[], config: Config[]) => {
  const pathRoomplan = module["8"];
  const pathUser = module["9"];
  const pathBank = module["10"];
  const pathRecord = module["11"];
  const pathApprove = module["12"];

  const pathInformation = module["14"];
  const pathAddress = module["15"];
  const pathRequestLoan = module["16"];
  const pathDocuments = module["17"];

  const pathRoomplanStatus = module["20"];
  const pathBorrowerStatus = module["21"];
  const pathCollateralStatus = module["22"];
  const pathUserStatus = module["23"];
  const pathStatus = module["24"];

  const pathLoanRequest = module["1"];
  const pathLoanStatus = module["2"];
  const pathReport = module["3"];
  const pathSetting = module["4"];
  const pathCompanySetting = module["5"];

  return {
    path: "/",
    element: protectedLayoutFunction(module, config),
    children: [
      {
        element: pathAfterLogin(module),
        index: true
      },
      { path: "loan/request/list", element: pathLoanRequest?.is_edit === 1 ? <LoanRequestList /> : <NotAuthorized /> },
      {
        path: "loan/request/:id",
        element: <LayoutLoanRequestAddData />,
        children: [
          { element: <Navigate to="roomplan" />, index: true },
          {
            path: "roomplan",
            element: roleCondition(pathRoomplan) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddDataRoomPlan /> : <NotAuthorized />
          },
          {
            path: "user",
            element: roleCondition(pathUser) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddDataUser /> : <NotAuthorized />
          },
          {
            path: "bank",
            element: roleCondition(pathBank) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddDataBank /> : <NotAuthorized />
          },
          {
            path: "record",
            element: roleCondition(pathRecord) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddDataRecord /> : <NotAuthorized />
          },
          {
            path: "approve",
            element: roleCondition(pathApprove) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddDataApprove /> : <NotAuthorized />
          }
        ]
      },
      {
        path: "loan/request/:id/:borrowerId",
        element: <LayoutLoanRequestAddUser />,
        children: [
          { element: <Navigate to="information" />, index: true },
          {
            path: "information",
            element:
              roleCondition(pathInformation) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddUserInformation /> : <NotAuthorized />
          },
          {
            path: "address",
            index: true,
            element: roleCondition(pathAddress) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddUserAddress /> : <NotAuthorized />
          },
          {
            path: "requestloan",
            element:
              roleCondition(pathRequestLoan) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddUserRequestLoan /> : <NotAuthorized />
          },
          {
            path: "documents",
            element: roleCondition(pathDocuments) && pathLoanRequest?.is_edit === 1 ? <LoanRequestAddUserDocuments /> : <NotAuthorized />
          }
        ]
      },
      { path: "loan/case/list", element: pathLoanStatus?.is_edit === 1 ? <LoanCaseList /> : <NotAuthorized /> },
      { path: "loan/status/list/:caseCode", element: pathLoanStatus?.is_edit === 1 ? <LoanStatusList /> : <NotAuthorized /> },
      {
        path: "loan/status/:caseCode/:loanId/:bankCode",
        element: <LayoutLoanStatusData />,
        children: [
          { element: <Navigate to="roomplan" />, index: true },
          {
            path: "roomplan",
            element: roleCondition(pathRoomplanStatus) && pathLoanStatus?.is_edit === 1 ? <LoanStatusDataRoomplan /> : <NotAuthorized />
          },
          {
            path: "loan",
            element: roleCondition(pathBorrowerStatus) && pathLoanStatus?.is_edit === 1 ? <LoanStatusDataLoan /> : <NotAuthorized />
          },
          {
            path: "user",
            element: roleCondition(pathUserStatus) && pathLoanStatus?.is_edit === 1 ? <LoanStatusDataBorrower /> : <NotAuthorized />
          },
          {
            path: "collateral",
            element: roleCondition(pathCollateralStatus) && pathLoanStatus?.is_edit === 1 ? <LoanStatusDataCollateral /> : <NotAuthorized />
          },
          {
            path: "status",
            element: roleCondition(pathStatus) && pathLoanStatus?.is_edit === 1 ? <LoanStatusDataStatus /> : <NotAuthorized />
          }
        ]
      },
      { path: "report", element: pathReport?.is_edit === 1 ? <Report /> : <NotAuthorized /> },
      {
        path: "setting",
        element: <LayoutSettingManagement />,
        children: [
          { element: <Navigate to="user-management" />, index: true },
          { path: "user-management", element: pathSetting?.is_edit === 1 ? <SettingUserManagement /> : <NotAuthorized /> },
          { path: "role-management", element: pathSetting?.is_edit === 1 ? <SettingRoleManagement /> : <NotAuthorized /> }
          // { path: "team-management", element: <SettingTeamManagement /> }
        ]
      },
      {
        path: "setting/user-management/:id",
        element: pathSetting?.is_edit === 1 ? <SettingUserData /> : <NotAuthorized />
      },
      // {
      //   path: "setting/user-management/1",
      //   element: <Navigate to="*" />
      // },
      {
        path: "setting/role-management/:id",
        element: pathSetting?.is_edit === 1 ? <SettingRoleData /> : <NotAuthorized />
      },
      {
        path: "setting/role-management/:id",
        children: [
          // { element: <Navigate to="user" />, index: true },
          { path: "user", element: pathSetting?.is_edit === 1 ? <SettingRoleManageUser /> : <NotAuthorized /> },
          { path: "role", element: pathSetting?.is_edit === 1 ? <SettingRoleManageRoleMenu /> : <NotAuthorized /> },
          {
            path: "role/:id",
            element: pathSetting?.is_edit === 1 ? <SettingRoleManageRolePermission /> : <NotAuthorized />,
            params: { hideSidebar: true }
          }
        ]
      },
      {
        path: "company-setting",
        element: <LayoutSettingCompany />,
        children: [
          { element: <Navigate to="company" />, index: true },
          { path: "company", element: pathCompanySetting?.is_edit === 1 ? <SettingCompany /> : <NotAuthorized /> },
          { path: "project", element: pathCompanySetting?.is_edit === 1 ? <SettingProject /> : <NotAuthorized /> },
          { path: "project-type", element: pathCompanySetting?.is_edit === 1 ? <SettingProjectType /> : <NotAuthorized /> },
          { path: "business", element: pathCompanySetting?.is_edit === 1 ? <SettingBusiness /> : <NotAuthorized /> },
          { path: "occupation", element: pathCompanySetting?.is_edit === 1 ? <SettingOccupation /> : <NotAuthorized /> },
          { path: "occupation-group", element: pathCompanySetting?.is_edit === 1 ? <SettingOccupationGroup /> : <NotAuthorized /> },
          { path: "document", element: pathCompanySetting?.is_edit === 1 ? <SettingDocument /> : <NotAuthorized /> },
          { path: "bank", element: pathCompanySetting?.is_edit === 1 ? <SettingBank /> : <NotAuthorized /> }
        ]
      },
      {
        path: "company-setting/company/:company_code",
        element: pathCompanySetting?.is_edit === 1 ? <SettingCompanyAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/project/:project_code",
        element: pathCompanySetting?.is_edit === 1 ? <SettingProjectAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/company/:company_code/:project_code",
        element: pathCompanySetting?.is_edit === 1 ? <SettingProjectAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/project-type/:project_type_id",
        element: pathCompanySetting?.is_edit === 1 ? <SettingProjectTypeAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/business/:business_id",
        element: pathCompanySetting?.is_edit === 1 ? <SettingBusinessAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/occupation/:occupation_id",
        element: pathCompanySetting?.is_edit === 1 ? <SettingOccupationAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/occupation-group/:occupation_group_id",
        element: pathCompanySetting?.is_edit === 1 ? <SettingOccupationGroupAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/document/:document_id",
        element: pathCompanySetting?.is_edit === 1 ? <SettingDocumentAdd /> : <NotAuthorized />
      },
      {
        path: "company-setting/bank/:bank_id",
        element: pathCompanySetting?.is_edit === 1 ? <SettingBankAdd /> : <NotAuthorized />
      }
    ]
  };
};

const ProfileRoutes = {
  path: "/profile",
  element: protectedLayoutHideSidebar,
  children: [{ path: "/profile", element: <Profile /> }]
};

const NotificationsRoutes = {
  path: "/notifications",
  element: protectedLayoutHideSidebar,
  children: [{ path: "/notifications", element: <Notifications /> }]
};

const AuthRoutes = {
  path: "/auth",
  element: <AuthLayout />,
  children: [
    { element: <Navigate to="login" />, index: true },
    { path: "login", element: <LoginForm /> }
  ]
};
const SetPasswordRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    { path: "users/verify/:token", element: <VerifyPasswordForm /> },
    { path: "users/reset/:token", element: <ResetPasswordForm /> },
    { path: "user/forgot-password", element: <ForgotPasswordForm /> }
  ]
};

const NotFoundRoutes = {
  path: "/",
  element: (
    <AuthChildren>
      <Outlet />
    </AuthChildren>
  ),
  children: [{ path: "*", element: <NotFound /> }]
};

const ThemeRoutes = () => {
  // const [myState, setState] = useState(skipToken) // initialize with skipToken to skip at first
  // const { data: profile, isFetching } = useProfileQuery();
  // undefined, { skip: true }
  // const { data: module } = useModuleQuery(undefined, { skip: true });
  const token = useSelector(selectToken);
  const { data: module, isLoading: moduleLoading } = useModuleQuery(undefined, { skip: !token?.data });
  const { data: config, isFetching: configIsFetching } = useConfigQuery(undefined, { skip: !token?.data });
  const moduleTobeUsed = customModule(module?.data || []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(switchLoader(moduleLoading || configIsFetching));
  }, [moduleLoading, configIsFetching]);

  return createBrowserRouter([
    // profile?.user?.role_id === 1 || process.env.REACT_APP_NODE_ENV === "production-dev" ?
    // MainRoutes,
    MainRoutesFunction(moduleTobeUsed, config?.data || []),
    //  : MainRoutesNotSetting,
    AuthRoutes,
    SetPasswordRoutes,
    ProfileRoutes,
    NotificationsRoutes,
    NotFoundRoutes
  ]);
};

export default ThemeRoutes;
