import React, { FC, useEffect, useState } from "react";
import { Modal, Space, ModalProps, Divider, ModalFuncProps, ButtonProps } from "antd";
import { SaveOutlined, LogoutOutlined, CheckOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { ButtonDefaultStyled, ButtonPrimaryStyled, TextStyled } from "src/components/styles";
import { ReactElementLike } from "prop-types";
import { ReactNode } from "react";
import styled from "styled-components";
import { fontWeight } from "src/styles/themes/constants";
// import { AdminProps } from "../../models/types";
import { useTranslation } from "react-i18next";
import AsyncLoadingButton from "src/components/common/AsyncLoadingButton/AsyncLoadingButton";

interface PropsConfirm extends ModalFuncProps {
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  footer?: JSX.Element | string;
  onClick?: () => void;
  modal?: any;
  t?: any;
  form?: any;
  onCancel?: () => void;
  textArea?: JSX.Element | string;
}

const timeout = () => new Promise((r) => setTimeout(r, 0));

export const ModalDelete = (props: PropsConfirm) => {
  const { modal, title, content, onClick, footer, t, form } = props;

  const md = modal.confirm({
    title: <TextStyled style={{ color: "var(--label-color)", fontWeight: `${fontWeight.semibold}` }}>{title}</TextStyled>,
    content: <TextStyled style={{ color: "var(--label-color)" }}>{content}</TextStyled>,
    footer: (
      <>
        <div style={{ marginInline: "-22px" }}>
          <Divider style={{ border: "1px solid #E9ECEF" }} />
        </div>
        <Space align={"center"} style={{ display: "flex", justifyContent: "end", marginTop: "-6px" }}>
          <ButtonPrimaryStyled color={"var(--cancel-color)"} onClick={() => md.destroy()}>
            {t("common.cancel")}
          </ButtonPrimaryStyled>
          <AsyncLoadingButton
            task={timeout}
            color={"var(--primary-color)"}
            style={{ color: "var(--white-color)" }}
            icon={<SaveOutlined />}
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                form();
              }
              md.destroy();
            }}
          >
            {t("common.confirm")}
          </AsyncLoadingButton>
        </Space>
      </>
    ),
    width: 443,
    centered: true
  });
};

export const ModalConfirmData = (props: PropsConfirm) => {
  const { modal, title, content, onClick, form, t, footer, onCancel } = props;

  const md = modal.info({
    icon: <CheckCircleOutlined />,
    title: <TextStyled style={{ color: "var(--label-color)", fontWeight: `${fontWeight.semibold}` }}>{title}</TextStyled>,
    content: <TextStyled style={{ color: "var(--label-color)" }}>{content}</TextStyled>,
    footer: (
      <>
        <div style={{ marginInline: "-22px" }}>
          <Divider style={{ border: "1px solid #E9ECEF" }} />
        </div>
        <Space align={"center"} style={{ display: "flex", justifyContent: "end", marginTop: "-6px" }}>
          <ButtonPrimaryStyled
            color={"var(--cancel-color)"}
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              md.destroy();
            }}
          >
            {t("common.cancel")}
          </ButtonPrimaryStyled>
          <AsyncLoadingButton
            id="async-btn-ok"
            task={timeout}
            icon={<SaveOutlined />}
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                form();
              }
              md.destroy();
            }}
          >
            {t("common.confirm")}
          </AsyncLoadingButton>
        </Space>
      </>
    ),
    width: 443,
    centered: true,
    onclose: () => console.log("onclose")
  });
};
export const ModalConfirmDataClose = (props: PropsConfirm) => {
  const { modal, title, content, textArea, onClick, form, t, footer, onCancel } = props;

  const md = modal.warning({
    // icon: <CheckCircleOutlined />,
    title: <TextStyled style={{ color: "var(--label-color)", fontWeight: `${fontWeight.semibold}` }}>{title}</TextStyled>,
    content: (
      <div>
        <TextStyled style={{ color: "var(--label-color)", marginLeft: "-12px" }}>{content}</TextStyled>
        {textArea}
      </div>
    ),

    footer: (
      <>
        <div style={{ marginInline: "-22px" }}>
          <Divider style={{ border: "1px solid #E9ECEF" }} />
        </div>
        <Space align={"center"} style={{ display: "flex", justifyContent: "end", marginTop: "-6px" }}>
          <ButtonPrimaryStyled
            color={"var(--cancel-color)"}
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              md.destroy();
            }}
          >
            {t("common.cancel")}
          </ButtonPrimaryStyled>
          <AsyncLoadingButton
            id="async-btn-ok"
            task={timeout}
            icon={<SaveOutlined />}
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                form();
              }
              md.destroy();
            }}
          >
            {t("common.confirm")}
          </AsyncLoadingButton>
        </Space>
      </>
    ),
    width: 443,
    centered: true,
    onclose: () => console.log("onclose")
  });
};

export const ModalSuccess = (props: PropsConfirm) => {
  const { modal, title, content, afterClose } = props;

  const md = modal.success({
    title: <TextStyled style={{ color: "var(--label-color)", fontWeight: `${fontWeight.semibold}` }}>{title}</TextStyled>,
    content: <TextStyled style={{ color: "var(--label-color)" }}>{content}</TextStyled>,
    footer: (
      <>
        <div style={{ marginInline: "-22px" }}>
          <Divider style={{ border: "1px solid #E9ECEF" }} />
        </div>
        <Space align={"center"} style={{ display: "flex", justifyContent: "end", marginTop: "-6px" }}>
          <ButtonPrimaryStyled id={"modal-ok"} onClick={() => md.destroy()}>
            {"ตกลง"}
          </ButtonPrimaryStyled>
        </Space>
      </>
    ),
    width: 443,
    centered: true,
    closable: false,
    afterClose: afterClose
  });
};

export const ModalError = (props: PropsConfirm) => {
  const { modal, title, content, afterClose } = props;

  const md = modal.error({
    title: <TextStyled style={{ color: "var(--label-color)", fontWeight: `${fontWeight.semibold}` }}>{title}</TextStyled>,
    content: <TextStyled style={{ color: "var(--label-color)" }}>{content}</TextStyled>,
    footer: (
      <>
        <div style={{ marginInline: "-22px" }}>
          <Divider style={{ border: "1px solid #E9ECEF" }} />
        </div>
        <Space align={"center"} style={{ display: "flex", justifyContent: "end", marginTop: "-6px" }}>
          <ButtonPrimaryStyled onClick={() => md.destroy()}>{"ปิด"}</ButtonPrimaryStyled>
        </Space>
      </>
    ),
    width: 443,
    centered: true,
    closable: false,
    afterClose: afterClose
  });
};
export const ModalWarning = (props: PropsConfirm) => {
  const { modal, title, content, afterClose } = props;

  const md = modal.warning({
    title: <TextStyled style={{ color: "var(--label-color)", fontWeight: `${fontWeight.semibold}` }}>{title}</TextStyled>,
    content: <TextStyled style={{ color: "var(--label-color)" }}>{content}</TextStyled>,
    footer: (
      <>
        <div style={{ marginInline: "-22px" }}>
          <Divider style={{ border: "1px solid #E9ECEF" }} />
        </div>
        <Space align={"center"} style={{ display: "flex", justifyContent: "end", marginTop: "-6px" }}>
          <ButtonPrimaryStyled onClick={() => md.destroy()}>{"ปิด"}</ButtonPrimaryStyled>
        </Space>
      </>
    ),
    width: 443,
    centered: true,
    closable: false,
    afterClose: afterClose
  });
};
