import styled from "styled-components";
import { Menu } from "antd";
import { fontWeight, media } from "src/styles/themes/constants";

export const MenuStyled = styled(Menu)`
  &.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: 0;
  }
  &.ant-menu-inline.ant-menu-root {
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
    padding: 20px;
    /* padding-inline: 30px; */
  }

  &.ant-menu-light {
    color: var(--dark-color);
    background: var(--white-color);
    height: 91vh;
    border-bottom-right-radius: 10px;
  }

  &.ant-menu-light .ant-menu-item-selected {
    color: var(--white-color);
    background: var(--menu-color);
  }

  &.ant-menu-item:not(.ant-menu-item-selected):hover {
    border-radius: 10px;
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-item {
    border-radius: 10px;
    margin-top: 16px;
    height: 50px;
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-item:nth-child(1) {
    margin-top: 16px;
  }
  &.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
    font-size: 16px;
  }
`;

export const MenuHorizontalStyled = styled(Menu)<{ $admin?: boolean }>`
  margin-bottom: 25px;
  &.ant-menu-horizontal {
    border-bottom: none;
    line-height: 40px;
    box-shadow: rgba(136, 152, 170, 0.15) 0px 0px 32px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 33px;
    width: fit-content;

    @media only screen and (${media.xl}) {
      width: auto;
    }
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item {
    padding-inline: 36px;
    margin: 0;
    border-radius: 30px;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item:active {
    background: #e6f4ff;
  }
  &.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    background: rgba(0, 0, 0, 0.06);
    border-radius: 30px;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
    span {
      color: var(--white-color);
    }
    background: var(--menu-color);
    border-radius: 30px;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border: 0;
    transition: none;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
    border: 0;
    transition: none;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    border: 0;
    transition: none;
  }
`;
export const MenuHorizontalSettingStyled = styled(Menu)`
  margin-bottom: 25px;
  &.ant-menu-horizontal {
    border-bottom: none;
    line-height: 40px;
    box-shadow: rgba(136, 152, 170, 0.15) 0px 0px 32px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 33px;
    width: fit-content;

    @media only screen and (${media.md}) {
      width: auto;
    }
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item {
    padding-inline: 36px;
    margin: 0 8px;
    border-radius: 30px;
    @media only screen and (${media.md}) {
      padding-inline: 16px;
      margin: 0;
    }
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item:active {
    background: #e6f4ff;
  }
  &.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    background: rgba(0, 0, 0, 0.06);
    border-radius: 30px;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
    span {
      color: var(--white-color);
    }
    background: var(--menu-color);
    border-radius: 30px;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border: 0;
    transition: none;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
    border: 0;
    transition: none;
  }
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    border: 0;
    transition: none;
  }
`;
