import styled from "styled-components";
import { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Drawer, Card } from "antd";
import { fontWeight, media } from "src/styles/themes/constants";

// project import
import { homeIcon } from "src/images";
import { totop } from "src/images/admin";
import list from "src/images/menu/list-svgrepo-com 1.svg";
import listCheck from "src/images/menu/list-check-svgrepo-com 1.svg";
import setting from "src/images/menu/setting-5-svgrepo-com 1.svg";
import report from "src/images/menu/report-file-svgrepo-com 1.svg";
import users from "src/images/menu/profile-2user-svgrepo-com 1.svg";

import { LinkStyled, IconStyled, MenuStyled, CardStyled, TextStyled } from "src/components/styles";

import type { MenuProps } from "antd/es/menu";
import MainLayoutHeader from "src/components/layouts/main/MainLayoutHeader";
import { useTranslation } from "react-i18next";
import { useScrollToTop, scrollToTop } from "src/hooks/useScrollToTop";
import { useProfileQuery } from "src/store/services/profile/profileApi";
import useProfilePicture from "src/hooks/useProfile/useProfilePicture";
import Button from "antd/lib/button";
import {
  AppstoreOutlined,
  BankOutlined,
  FileSearchOutlined,
  LeftOutlined,
  PartitionOutlined,
  ProjectOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons";
// import { menuListCheck } from "src/images/menu/menuFuction";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(label: React.ReactNode, key?: React.Key | null, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

interface Props {
  hideSidebar?: boolean;
  module?: any;
  config?: any;
}

const MainLayout: FC<Props> = ({ hideSidebar, module, config }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const [mobile, setMobile] = useState(window.innerWidth <= 992);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: profile, refetch: refetchProfile } = useProfileQuery();
  const { profilePicture, refetchProfilePicture, profilePictureIsSuccess, profilePictureIsError, profilePictureIsFetching } =
    useProfilePicture(profile?.user.profile_id);
  const displayed = useScrollToTop(() => window.pageYOffset > 1);
  const menuLoanRequest = module?.["1"];
  const menuLoanStatus = module?.["2"];
  const menuReport = module?.["3"];
  const menuSetting = module?.["4"];
  const menuCompany = module?.["5"];

  const items: MenuItem[] = [
    menuLoanRequest?.is_edit === 1
      ? getItem(visible && `${t("layout.dev.sider.loanRequest")}`, "/loan/request/list", <IconStyled src={list} alt="list" />)
      : null,
    menuLoanStatus?.is_edit === 1
      ? getItem(visible && `${t("layout.dev.sider.loanStatus")}`, "/loan/case/list", <IconStyled src={listCheck} alt="listCheck" />)
      : null,
    menuReport?.is_edit === 1
      ? getItem(visible && `${t("layout.dev.sider.report")}`, "/report", <IconStyled src={report} alt="report" />)
      : null,
    menuSetting?.is_edit === 1
      ? getItem(visible && `${t("layout.dev.sider.manageUsers")}`, "/setting/user-management", <IconStyled src={users} alt="users" />)
      : null,
    menuCompany?.is_edit === 1
      ? getItem(visible && `${t("layout.dev.sider.setting")}`, "/company-setting/company", <IconStyled src={setting} alt="setting" />)
      : null
  ];
  const itemsLayoutSetting: MenuItem[] = [
    getItem(visible && `${t("layout.dev.sider.setting")}`, "/sub1", <IconStyled src={setting} alt="setting" />, [
      getItem(visible && `${t("page.settingCompany.header.title")}`, `/company-setting/company`, <AppstoreOutlined />),
      getItem(visible && `${t("page.settingProject.header.title")}`, `/company-setting/project`, <ProjectOutlined />),
      getItem(visible && `${t("page.settingProjectType.header.title")}`, `/company-setting/project-type`, <ReconciliationOutlined />),
      getItem(visible && `${t("page.settingBusiness.header.title")}`, `/company-setting/business`, <PartitionOutlined />),
      getItem(visible && `${t("page.settingOccupation.header.title")}`, `/company-setting/occupation`, <UserOutlined />),
      getItem(visible && `${t("page.settingOccupationGroup.header.title")}`, `/company-setting/occupation-group`, <TeamOutlined />),
      getItem(visible && `${t("page.settingDocument.header.title")}`, `/company-setting/document`, <FileSearchOutlined />),
      getItem(visible && `${t("page.settingBank.header.title")}`, `/company-setting/bank`, <BankOutlined />)
    ])
  ];

  const replaceLocationPathname = (locationPathname: string) => {
    if (locationPathname === "/" || locationPathname.slice(0, 13) === "/loan/request") {
      return "/loan/request/list";
    } else if (location.pathname.slice(0, 8) === "/setting") {
      return "/setting/user-management";
    } else if (location.pathname.slice(0, 12) === "/loan/status") {
      return "/loan/case/list";
    }

    return locationPathname;
  };

  const menuSider = (
    <>
      {location.pathname.slice(0, 8) === "/company" ? (
        <div>
          <ButtonBack onClick={() => navigate("/")}>
            <LeftOutlinedCustom style={{ marginLeft: "48px", color: "var(--dark-color)", marginTop: "5px" }} />
            <TextStyled
              style={{
                marginLeft: "10px",
                marginTop: "4px",
                color: "var(--dark-color)",
                fontSize: "18px",
                fontWeight: `${fontWeight?.semibold}`,
                display: !visible ? "none" : "block"
              }}
            >
              {t("page.settingCompany.back")}
            </TextStyled>
          </ButtonBack>
          <MenuCustomStyled
            visible={visible}
            mode="inline"
            defaultOpenKeys={["/sub1"]}
            selectedKeys={[replaceLocationPathname(location.pathname)]}
            items={itemsLayoutSetting}
            onClick={({ key }) => navigate(key)}
          />
        </div>
      ) : (
        <MenuStyled
          mode="inline"
          selectedKeys={[replaceLocationPathname(location.pathname)]}
          items={items}
          onClick={({ key }) => navigate(key)}
        />
      )}
    </>
  );

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 992);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (mobile) setVisible(false);
    else setVisible(true);
  }, [mobile]);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <MainLayoutHeader
          visible={visible}
          setVisible={setVisible}
          onClick={() => setVisible(!visible)}
          profile={profile}
          refetchProfile={refetchProfile}
          profilePicture={profilePicture}
          refetchProfilePicture={refetchProfilePicture}
          profilePictureIsSuccess={profilePictureIsSuccess}
          profilePictureIsError={profilePictureIsError}
          profilePictureIsFetching={profilePictureIsFetching}
        />
        <LayoutCustomStyled>
          <Layout.Sider
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {}}
            trigger={null}
            width={!visible ? 114 : 300}
            className="sider-primary ant-layout-sider-primary"
            style={{
              background: "var(--background-color)",
              height: "80vh",
              display: hideSidebar ? "none" : "block"
            }}
          >
            {menuSider}
          </Layout.Sider>

          <LayoutContentCustomStyled>
            <Outlet
              context={{
                profile,
                refetchProfile,
                profilePicture,
                refetchProfilePicture,
                profilePictureIsSuccess,
                profilePictureIsError,
                profilePictureIsFetching,
                module,
                config
              }}
            />
          </LayoutContentCustomStyled>
        </LayoutCustomStyled>
        <Layout.Footer style={{ background: "var(--background-color)", textAlign: "center", marginTop: "-30px" }}>
          ICON MORTGAGE DEVELOPER PORTAL
          <br />© 2023 ICON Framework CO.,Ltd.
        </Layout.Footer>
        {displayed && (
          <img src={totop} style={{ position: "fixed", bottom: 12, right: 12, cursor: "pointer", width: "40px" }} onClick={scrollToTop} />
        )}
      </Layout>
    </>
  );
};

export default MainLayout;

const LayoutStyled = styled(Layout)``;
const LayoutCustomStyled = styled(Layout)`
  height: 100%;
  background: var(--background-color);
  padding: 0;
  /* padding: 32px 24px 0px 24px; */

  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
`;

const DivCustomStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 16px;
  border-radius: 50px;
  margin-bottom: 16px;
  background: #dee7ed;
`;

const LayoutContentCustomStyled = styled(Layout.Content)`
  background: var(--background-color);
  /* padding-left: 24px;
  padding-bottom: 24px; */
  padding: 24px;
  /* @media only screen and (${media.lg}) {
    padding-left: 0px;
  } */
`;
const MenuCustomStyled = styled(MenuStyled)<{ visible?: boolean }>`
  &.ant-menu-light .ant-menu-item-selected {
    color: var(--primary-color);
    background: #d5ddf5;
    box-shadow: 0px 2px 5px 1px #dddedf23;
    border-right: 6px solid;
  }

  &.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: var(--dark-color);
    height: 50px;
    margin-bottom: 14px;
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #eff3f6;
    border-radius: 10px;
    padding-bottom: 14px;
    padding-right: -20px;
    padding-left: 0px;
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline > li {
    border-radius: 0px;
    margin-left: 4px;
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline > li > span {
    margin-left: ${(props) => (props?.visible ? "" : "-22px")};
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline > li > span > svg {
    font-size: 16px;
  }
  &.ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    display: ${(props) => (props?.visible ? "block" : "none")};
  }
`;

const LeftOutlinedCustom = styled(LeftOutlined)`
  & * {
    font-size: 22px;
    /* margin: -20px 20px -9px -10px; */
  }
`;
const ButtonBack = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #e9ecef;
  height: 84px;
  cursor: pointer;
`;
