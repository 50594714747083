import {
  Prefix,
  ResponsePrefix,
  Gender,
  ResponseGender,
  Occupation,
  ResponseOccupation,
  MarriedStatus,
  ResponseMarriedStatus,
  Nationality,
  ResponseNationality,
  District,
  ResponseDistrict,
  SubDistrict,
  ResponseSubDistrict,
  Province,
  ResponseProvince,
  Business,
  ResponseBusiness,
  Bureau,
  ResponseBureau,
  Relationship,
  ResponseRelationship,
  Country,
  ResponseCountry,
  Education,
  ResponseEducation,
  ResponseUserMaster,
  ResponseProjectMaster,
  DistrictQuery,
  SubDistrictQuery,
  Bank,
  ResponseBank,
  ResponseStatusLoan,
  ResponseCompany,
  ResponseBankStatus,
  ResponseCustomerList,
  RequestCustomerList,
  ResponseBankGroup
} from "src/models/api/master.model";
import { api } from "./api";

interface Query {
  query: string;
}

export const masterApi = api.enhanceEndpoints({ addTagTypes: ["Master"] }).injectEndpoints({
  endpoints: (builder) => ({
    getPrefix: builder.query<ResponsePrefix, Query>({
      query: (Query) => ({
        url: "internals/master-setting/prefix",
        method: "POST",
        body: Query
      })
    }),
    getGender: builder.query<ResponseGender, Query>({
      query: (Query) => ({
        url: "internals/master-setting/gender",
        method: "POST",
        body: Query
      })
    }),
    getOccupation: builder.query<ResponseOccupation, Query>({
      query: (Query) => ({
        url: "internals/master-setting/occupation",
        method: "POST",
        body: Query
      })
    }),
    getMarriedStatus: builder.query<ResponseMarriedStatus, Query>({
      query: (Query) => ({
        url: "internals/master-setting/married-status",
        method: "POST",
        body: Query
      })
    }),
    getNationality: builder.query<ResponseNationality, Query>({
      query: (Query) => ({
        url: "internals/master-setting/nationality",
        method: "POST",
        body: Query
      })
    }),
    getDistrict: builder.query<ResponseDistrict, DistrictQuery>({
      query: (Query) => ({
        url: "internals/master-setting/district",
        method: "POST",
        body: Query
      })
    }),
    getSubDistrict: builder.query<ResponseSubDistrict, SubDistrictQuery>({
      query: (Query) => ({
        url: "internals/master-setting/sub-district",
        method: "POST",
        body: Query
      })
    }),
    getProvince: builder.query<ResponseProvince, Query>({
      query: (Query) => ({
        url: "internals/master-setting/province",
        method: "POST",
        body: Query
      })
    }),
    getCountry: builder.query<ResponseCountry, Query>({
      query: (Query) => ({
        url: "internals/master-setting/country",
        method: "POST",
        body: Query
      })
    }),
    getRelationship: builder.query<ResponseRelationship, Query>({
      query: (Query) => ({
        url: "internals/master-setting/relationship",
        method: "POST",
        body: Query
      })
    }),
    getBureau: builder.query<ResponseBureau, Query>({
      query: (Query) => ({
        url: "internals/master-setting/bureau",
        method: "POST",
        body: Query
      })
    }),
    getBusiness: builder.query<ResponseBusiness, Query>({
      query: (Query) => ({
        url: "internals/master-setting/business",
        method: "POST",
        body: Query
      })
    }),
    getEducation: builder.query<ResponseEducation, Query>({
      query: (Query) => ({
        url: "internals/master-setting/education",
        method: "POST",
        body: Query
      })
    }),
    getBank: builder.query<ResponseBank, Query>({
      query: (Query) => ({
        url: "internals/master-setting/bank",
        method: "POST",
        body: Query
      })
    }),
    getProject: builder.query<ResponseProjectMaster, void>({
      query: () => "internals/master/project"
    }),
    getProjectList: builder.query<ResponseProjectMaster, { company_code: string | null}>({
      query: (Query) => ({ url: "internals/master/get/project/list" , method: "POST" , body: Query})
    }),
    getUsers: builder.query<ResponseUserMaster, void>({
      query: () => ({ url: "internals/master/user/select", method: "POST" })
    }),
    getStatusLoan: builder.query<ResponseStatusLoan, void>({
      query: () => "internals/master/status/loan"
    }),
    getCompany: builder.query<ResponseCompany, void>({
      query: () => "internals/master/company"
    }),
    getCompanyList: builder.query<ResponseCompany, void>({
      query: () => ({
        url: "internals/master/get/company/list",
        method: "POST"
      })
    }),
    getBankStatus: builder.query<ResponseBankStatus, void>({
      query: () => "internals/master/status/bank"
    }),
    getCustomerList: builder.query<ResponseCustomerList, RequestCustomerList>({
      query: (Query) => ({
        url: "internals/loan/get/customer_lists",
        method: "POST",
        body: Query
      })
    }),
    getBankGroup : builder.query<ResponseBankGroup, void>({
      query: () => ({
        url: "internals/master/status/bank/group",
        method: "POST",
      })
    })
  })
});

export const {
  useGetPrefixQuery,
  useGetGenderQuery,
  useGetOccupationQuery,
  useGetMarriedStatusQuery,
  useGetNationalityQuery,
  useGetDistrictQuery,
  useGetSubDistrictQuery,
  useGetProvinceQuery,
  useGetCountryQuery,
  useGetRelationshipQuery,
  useGetBureauQuery,
  useGetBusinessQuery,
  useGetEducationQuery,
  useGetBankQuery,
  useGetProjectQuery,
  useGetProjectListQuery,
  useGetUsersQuery,
  useGetStatusLoanQuery,
  useGetCompanyQuery,
  useGetCompanyListQuery,
  useGetBankStatusQuery,
  useGetCustomerListQuery,
  useGetBankGroupQuery,

} = masterApi;
