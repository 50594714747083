import { css } from "styled-components";

export const resetCss = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", "Noto Sans Thai", sans-serif !important;
    background: var(--backgroud-color);
    font-size: 14px;
    font-weight: 400;
    /* min-width: 480px; */
  }

  ::-webkit-scrollbar {
    width: 0.01rem;
  }

  &.ant-table-content::-webkit-scrollbar {
    width: 20rem !important;
    /* width of the entire scrollbar */
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    border: 0.375rem solid transparent;
    background-clip: content-box;
  }
`;
