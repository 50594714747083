import styled from "styled-components";
import { AutoComplete } from "antd";

export const AutoCompleteStyled = styled(AutoComplete)`
  height: 39px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--input-color);
  background: var(--white-color);
  &.ant-input {
    color: var(--input-color);
    background-color: var(--white-color);
  }
  &.ant-input[disabled],
  .ant-input-disabled {
    color: var(--input-color);
    background-color: #E9ECEF;
    border-color: #DEE2E6;
  }
  &.ant-input-disabled,
  .ant-input[disabled] {
    cursor: default;
    .ant-input-input {
      cursor: default;
    }
  }
`;