import { string } from "prop-types";
import { CreateSettingRole, DataSettingRole, EditSettingRole, SettingRole, DataSettingRoleUser } from "src/models/api/settingRole.model";
import { apiSetting } from "src/store/services/api";
import { ModuleResponse } from "src/models/api/settingModule.model";
export const settingModuleApi = apiSetting.enhanceEndpoints({ addTagTypes: ["SettingModule"] }).injectEndpoints({
  endpoints: (builder) => ({
    moduleInfo: builder.query<any, number | string>({
      query: (id) => ({
        url: "internals/master/module/info",
        method: "POST",
        body: { roleId: id }
      }),
      providesTags: ["SettingModule"]
    }),
    moduleChild: builder.query<any, any>({
      query: (data) => ({
        url: "internals/master/module/child",
        method: "POST",
        body: data
      }),
      providesTags: ["SettingModule"]
    }),
    module: builder.query<ModuleResponse, void>({
      query: () => ({
        url: "internals/master/module",
        method: "GET"
      }),
      providesTags: ["SettingModule"]
    })
  })
});

export const { useModuleInfoQuery, useModuleChildQuery, useModuleQuery } = settingModuleApi;
