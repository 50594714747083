import { FC, useEffect } from "react";
import { Popover, Space, Modal } from "antd";
import styled from "styled-components";
import { TextStyled } from "src/components/styles";
import { pen, signout} from "src/images";
import { WithChildrenProps } from "src/models/types";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { selectProfile } from "src/store/services/profile/profileApi";
import { useSelector } from "react-redux";
import useAuth from "src/hooks/useAuth";
import { ModalSuccess } from "src/components/modals/ModalsComponent";

interface Props {
  locationPathname?: string;
}

const UserMenu: FC<WithChildrenProps<Props>> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modal, contextHolder] = Modal.useModal();
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const { logout } = useAuth();

  const logoutHandle = async (username: any) => {
    if (localStorage.getItem("checked") === "false") {
      localStorage.removeItem("username");
    }
    logout(`${username}`)
      .unwrap()
      .then(() => {
        ModalSuccess({
          modal: modal,
          title: t("common.modal.success.title") ?? "",
          content: "ออกจากระบบสำเร็จ",
          afterClose: () => {
            localStorage.removeItem("token");
            navigate("/auth/login");
          }
        });
      })
      .catch(() => {
        ModalSuccess({
          modal: modal,
          title: t("common.modal.error.title") ?? "",
          content: t("common.modal.error.content") ?? "",
          afterClose: () => {
            localStorage.removeItem("token");
            navigate("/auth/login");
          }
        });
      });
  };

  useEffect(() => {}, [location, navigate]);

  return (
    <Popover
      arrow={false}
      placement="bottomRight"
      overlayInnerStyle={{
        height: 110,
        paddingBlock: "12px",
        paddingInline: "6px",
        minWidth: 190,
        marginTop: "-10px",
        boxShadow: "0px 2px 10px 2px rgba(37, 60, 117, 0.1)"
      }}
      content={
        <Space direction={"vertical"} size={10}>
          <ButtonUserMenu locationPathname={location.pathname} onClick={() => navigate("/profile")}>
            <img src={pen} style={{ marginLeft: "10px", marginRight: "20px" }} />
            <TextStyled>{t("layout.dev.header.editProfile")}</TextStyled>
          </ButtonUserMenu>
          <ButtonUserMenu onClick={() => logoutHandle(profile?.data?.user?.username)}>
            <img src={signout} style={{ marginLeft: "10px", marginRight: "18px" }} />
            <TextStyled>{t("layout.dev.header.signOut")}</TextStyled>
          </ButtonUserMenu>
        </Space>
      }
      trigger="click"
    >
      {children}
      {contextHolder}
    </Popover>
  );
};

export default UserMenu;

const ButtonUserMenu = styled.div<Props>`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 190px;
  height: 36px;
  cursor: pointer;
  border-radius: 10px;
  background: ${(props) => (props.locationPathname === "/profile" ? "rgba(94, 114, 228, 0.07)" : "var(--white-color)")};
  padding-inline: 10px;
  :hover {
    background: "rgba(94, 114, 228, 0.07)";
  }
  ${TextStyled} {
    color: var(--label-color);
  }
`;
