import { Profile, User } from "src/models/api/profile.model";
import { apiSetting } from "src/store/services/api";

export const profileApi = apiSetting.enhanceEndpoints({ addTagTypes: ["Profile"] }).injectEndpoints({
  endpoints: (builder) => ({
    profile: builder.query<Profile, void>({
      query: () => "internals/master/profile",
      providesTags: ["Profile"]
    }),
    profileDevelop: builder.query<any, void>({
      query: () => "internals/master/profile/developer",
      providesTags: ["Profile"]
    }),
    updateProfileDevelop: builder.mutation<void, any>({
      query: (body) => ({
        url: "internals/master/profile/developer/edit",
        method: "POST",
        body
      }),
      invalidatesTags: ["Profile"]
    }),
    updateProfile: builder.mutation<void, any>({
      query: (body) => ({
        url: "internals/master/profile/edit",
        method: "POST",
        body
      }),
      invalidatesTags: ["Profile"]
    }),
    setChangePassword: builder.mutation<void, { currentPassword: string; newPassword: string }>({
      query: (body) => ({
        url: "user/set-change-password",
        method: "POST",
        body
      }),
      invalidatesTags: ["Profile"]
    }),
    profilePicture: builder.query<any, string>({
      query: (id) => ({
        url: `internals/files/${id}`,
        method: "POST",
        responseHandler: (response) => response.blob().then((blob) => URL.createObjectURL(blob)),
        cache: "no-cache"
      }),
      providesTags: ["Profile"]
    }),
    uploadProfilePicture: builder.mutation<any, any>({
      query: (File) => {
        return {
          url: "internals/files/profile",
          method: "POST",
          body: File,
          invalidatesTags: ["Profile"]
          // keepUnusedDataFor: 0
        };
      }
    }),
    deleteProfilePicture: builder.mutation<any, any>({
      query: (id) => ({
        url: `internals/files/${id}/delete`,
        method: "POST"
      }),
      invalidatesTags: ["Profile"]
    })
  })
});

export const {
  useProfileQuery,
  useProfileDevelopQuery,
  useUpdateProfileMutation,
  useSetChangePasswordMutation,
  useProfilePictureQuery,
  useUploadProfilePictureMutation,
  useDeleteProfilePictureMutation,
  useUpdateProfileDevelopMutation
} = profileApi;

export const selectProfile = profileApi.endpoints.profile.select();
