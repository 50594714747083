import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Loan } from "src/models/api/loan.model";
import update from "lodash/update";

type LoanRequest = {
  case_code:string;
  loan_id: string;
  company_code:string;
  unit_name:string;
  unit_code:string;
  approve: {
    selected: {
      id: string;
    };
  };
  collateral_price:number | null;
  unit_name_input: string;
};

const initialValues: LoanRequest = {
  case_code: localStorage.getItem("@dev-portal/case_code") || "",
  loan_id: localStorage.getItem("@dev-portal/loan_id") || "",
  company_code:"",
  unit_name:"",
  unit_name_input: "",
  unit_code:"",
  approve: {
    selected: {
      id: ""
    }
  },
  collateral_price: null
};

export const loanRequest = createSlice({
  name: "loanRequest",
  initialState: initialValues,
  reducers: {
    resetLoan: (state: LoanRequest, action: PayloadAction<string>) => {
      if (action.type === resetLoan.type) {
        return { ...initialValues, id: action.payload };
      }
    },
    updateLoan: (state: LoanRequest, action: PayloadAction<{ value: any; type: string }>) => {
      if (action.type === updateLoan.type) {
        update(state, action.payload.type, (v) => action.payload.value);
      }
    },
    selectApprove: (state: LoanRequest, action: PayloadAction<string>) => {
      if (action.type === selectApprove.type) {
        update(state, "approve.selected.id", (v) => action.payload);
      }
    }
  }
});

export const { updateLoan, resetLoan, selectApprove } = loanRequest.actions;
export const loanRequestSelector = (store: RootState) => store.loanReducer;
export const selectCaseCode = (state: RootState) => state.loanReducer.case_code;
export const selectLoanId = (state: RootState) => state.loanReducer.loan_id;
export const selectCompanyCode = (state: RootState) => state.loanReducer.company_code;
export const selectUnitName = (state: RootState) => state.loanReducer.unit_name;
export const selectUnitCode = (state: RootState) => state.loanReducer.unit_code;
export const selectApproveBankId = (state: RootState) => state.loanReducer.approve.selected.id
export const selectPrice = (state: RootState) => state.loanReducer.collateral_price
export const selectUnitNameInput = (state: RootState) => state.loanReducer.unit_name_input
export default loanRequest.reducer;
