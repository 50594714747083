import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import update from "lodash/update";
import dayjs from "dayjs";

type Filter = {
    status: string,
    startDate: dayjs.Dayjs | null,
    endDate: dayjs.Dayjs | null,
    filterStatus: (React.Key | boolean)[],
    searchFilter: string,
    company: string,
    project: string,
    salesperson: string
}

const initialValues: Filter = {
    status: "",
    startDate: null,
    endDate: null,
    filterStatus: [],
    searchFilter: "",
    company: "",
    project: "",
    salesperson: ""
};

export const filterRequest = createSlice({
    name: "filterRequest",
    initialState: initialValues,
    reducers: {
      updateFilter: (state: Filter, action: PayloadAction<{ value: any; type: string }>) => {
        if (action.type === updateFilter.type) {
          update(state, action.payload.type, (v) => action.payload.value);
        }
      }
    }
  });

  export const { updateFilter } = filterRequest.actions;
  export const requestListSelector = (store: RootState) => store.requestListReducer;
  export default filterRequest.reducer;

