import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { authApi } from "src/store/services/authApi";
// import { authCheckTokenApi } from "src/store/services/authCheckTokenApi";
import { RootState } from "src/store/store";

interface InitialState {
  isLoading: boolean;
}

const initialState: InitialState = {
  isLoading: false
};

const loader = createSlice({
  name: "loader",
  initialState,
  reducers: {
    switchLoader: (state: InitialState, action: PayloadAction<boolean>) => {
      return { ...initialState, isLoading: action.payload };
    }
  }
});

export const { switchLoader } = loader.actions;
export const switchLoaderSelector = (store: RootState) => store.loaderReducer.isLoading;

export default loader.reducer;
