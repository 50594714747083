import { Module } from "src/models/api/settingModule.model";

export const customModule = (module: Module[]) => {

    let arr: any = {};
    module.forEach((element) => {
        arr[`${element.id}`] = element
    });

    return arr
}