import { Table } from "antd";
import { TableProps } from "antd/es/table";
import { TextStyled } from "src/components/styles";
import { fontWeight } from "src/styles/themes/constants";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { NavLink } from "react-router-dom";
import type { ColumnType } from "antd/es/table";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "src/components/common/Table/SearchFilterDropdown";
import { ConditionalWrapper } from "src/components/common/Wrapper/ConditionalWrapper";

export class TableCommonProps {
  static getColumnSearchProps = (
    placeholder: string,
    searchInputRef: any,
    searchedTable: any,
    setSearchedTable: any,
    dataIndex: string,
    hideSorter?:boolean
  ): ColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm, close }) => (
      <SearchFilterDropdown
        searchInputRef={searchInputRef}
        placeholder={placeholder}
        onSearch={(value) => {
          setSelectedKeys([value]);
        }}
        confirm={() => {
          if (selectedKeys[0] === "" && clearFilters !== undefined) {
            clearFilters!();
          }
          confirm({ closeDropdown: false });
          setSearchedTable((v: any) => ({ ...v, [dataIndex]: (selectedKeys as string[])[0] }));
        }}
        close={close}
      />
    ),
    filterIcon: () => <SearchOutlined />,
    onFilter: (value, record: any) =>
      record?.[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select(), 100);
      }
    },
    sorter: !hideSorter ? (a: any, b: any) => a[dataIndex].toString().localeCompare(b[dataIndex].toString()) : false,
    render: (text) => <HighlighterStyled searchWords={[searchedTable[dataIndex]]} textToHighlight={text ? text.toString() : ""} />
  });

  static getColumnMultiSearchProps = (
    placeholder: string,
    searchInputRef: any,
    searchedTable: any,
    setSearchedTable: any,
    dataList: string[],
    labelList: string[],
    linkTo?: string,
    handleLinkClick?: () => void
  ): ColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm, close }) => (
      <SearchFilterDropdown
        searchInputRef={searchInputRef}
        placeholder={`${placeholder} ${labelList.join(", ")}`}
        onSearch={(value) => {
          setSelectedKeys([value]);
        }}
        confirm={() => {
          if (selectedKeys[0] === "" && clearFilters !== undefined) {
            clearFilters!();
          }
          confirm({ closeDropdown: false });
          setSearchedTable((v: any) => ({ ...v, [dataList[0]]: (selectedKeys as string[])[0] }));
        }}
        close={close}
      />
    ),
    onFilter: (value, record) =>
      dataList.reduce(
        (filter, v) =>
          record?.[v]
            ?.toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()) || filter,
        false
      ),
    render: (val, data) => (
      <ConditionalWrapper condition={!!linkTo} wrapper={(children) => <NavLink to={linkTo!.replace(":id", val)} onClick={handleLinkClick}>{children}</NavLink>}>
        {dataList.map((v, i) => {
          return (
            <div key={v + data[v]}>
              {labelList[i]}:{" "}
              <HighlighterStyled searchWords={[searchedTable[dataList[0]]]} textToHighlight={data[v] ? data[v].toString() : "-"} />
              <br />
            </div>
          );
        })}
      </ConditionalWrapper>
    )
  });

  static getColumnMultiSearchPropsCustom = (
    placeholder: string,
    searchInputRef: any,
    setSearchedTable: any,
    dataList: string[],
    labelList: string[],
  ): ColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm, close }) => (
      <SearchFilterDropdown
        searchInputRef={searchInputRef}
        placeholder={`${placeholder} ${labelList.join(", ")}`}
        onSearch={(value) => {
          setSelectedKeys([value]);
        }}
        confirm={() => {
          if (selectedKeys[0] === "" && clearFilters !== undefined) {
            clearFilters!();
          }
          confirm({ closeDropdown: false });
          setSearchedTable((v: any) => ({ ...v, [dataList[0]]: (selectedKeys as string[])[0]}));
        }}
        close={close}
      />
    ),
    filterIcon: () => <SearchOutlined />,
    onFilter: (value, record) =>
      dataList.reduce(
        (filter, v) =>
          record?.[v]
            ?.toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()) || filter,
        false
      )
  });
}

export const TableStyled = (props: TableProps<any>) => {
  const { t } = useTranslation();
  const { dataSource = [], pagination, ...rest } = props;

  return (
    <DivTableStyled>
      <Table
        dataSource={dataSource}
        pagination={
          pagination === false
            ? false
            : {
                showTotal: (total, [first, last]) => (
                  <>
                    <TextStyled color={"var(--label-color)"}>
                      {t("common.table.total", { count: total, show: last - first + 1 })}
                    </TextStyled>
                  </>
                ),
                ...pagination,
                pageSizeOptions:[5,10, 20, 50, 100]
              }
        }
        scroll={{ x: 800 }}
        {...rest}
      />
    </DivTableStyled>
  );
};

export const TabelMainStyled = styled(({ children, style, ...props }: any) => {
  return (
    <div
      style={{
        marginLeft: "-24px",
        marginRight: "-24px"
      }}
    >
      <TableStyled {...props}>{children}</TableStyled>
    </div>
  );
})``;

const DivTableStyled = styled.div`
  a {
    img {
      transition: ease-in-out 200ms;
      :hover:not(:active) {
        opacity: 0.5;
      }
    }
    :not(:hover) {
      color: var(--primary-color);
    }
    text-decoration: underline;
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > th .ant-typography,
  .ant-table-wrapper .ant-table-thead > tr > th .ant-table-column-title {
    color: var(--gray-color);
    font-weight: ${fontWeight.bold};
  }
  .ant-table-wrapper .active {
    color: var(--primary-color);
  }
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > th,
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
    background: rgba(94, 114, 228, 0.07);
  }
  td.ant-table-cell {
    color: var(--label-color);
  }
  .ant-table-wrapper .ant-table-footer {
    background-color: transparent;
  }
  & ::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background-clip: content-box;
  }
  &:hover ::-webkit-scrollbar-thumb {
    background-color: var(--gray-color);
  }
  .ant-pagination .ant-pagination-total-text {
    margin-inline-end: auto;
    margin-inline-start: 28px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .ant-checkbox .ant-checkbox-input {
    color: var(--primary-color);
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primary-color);
  }
`;

export const HighlighterStyled = styled(Highlighter).attrs((props) => {
  return {
    highlightStyle: {
      backgroundColor: "var(--primary-color)",
      color: "var(--white-color)",
      fontWeight: fontWeight.semibold
    },
    autoEscape: true
  };
})``;
